import './PixAdminCreation.css'

import { Button, Col, Form, Input } from 'antd'
import { useCreatePix } from 'hooks/pix'
import { AuthModes } from 'services/PixControllerService/enums'

const PixScreen = () => {

  const {
    mode,
    onPressValidateUser,
    onPressGeneratePix
  } = useCreatePix()


  if(mode === AuthModes.GeneratePix) {
    return (
      <div className='container-pix'>
        <h1>Dados para gerar PIX</h1>
        <Col xs={16} md={12} xl={8}>
          <Form
            initialValues={{ remember: true }}
            onFinish={onPressGeneratePix}
          >
            <Form.Item
              name="bookingId"
              rules={[
                { required: true, message: 'Insira o id do booking' },
              ]}
            >
              <Input placeholder="Id do booking" size="large" />
            </Form.Item>

            <Form.Item
              name="invoiceId"
              rules={[
                { required: true, message: 'Insira o id do invoice' },
              ]}
            >
              <Input type='number' placeholder="Id do invoice" size="large" />
            </Form.Item>

            <Form.Item
              name="value"
              rules={[
                { required: true, message: 'Insira o valor' },
              ]}
            >
              <Input type='number' placeholder="Valor" size="large" />
            </Form.Item>
        
            <Form.Item>
              <Button id="booking-search-submit-button" htmlType="submit" type="primary">
                Gerar PIX
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </div>
    )
  }

  return (
    <div className='container-pix'>
      <h1>Confirmação de usuário</h1>
      <Col xs={16} md={12} xl={8}>
        <Form
          initialValues={{ remember: true }}
          onFinish={onPressValidateUser}
        >
          <Form.Item
            name="username"
            rules={[
              { required: true, message: 'Insira o usuário' },
            ]}
          >
            <Input placeholder="Usuário" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Insira a senha' },
            ]}
          >
            <Input type='password' placeholder="Senha" size="large" />
          </Form.Item>
        
          <Form.Item>
            <Button id="booking-search-submit-button" htmlType="submit" type="primary">
              Confirmar
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </div>
  )
}

export default PixScreen