import PIX, { type GeneratePixProps } from 'apis/PIX'
import modalController from 'components/Modal'
import { setWebsiteLoading } from 'services/LoadingService'

export const validateUserCredentialsService = (
  values: any,
  callbackSuccess: () => void
) => {
  try {
    if(values.username !== process.env.REACT_APP_USERNAME_ADMIN) throw new Error('Usuário incorreto')
    if(values.password !== process.env.REACT_APP_PASSWORD_ADMIN) throw new Error('Senha incorreta')

    callbackSuccess()
  } catch(error: any) {
    modalController.openInfoModal({ title: error?.message })
  }
}

export const createPixService = async (
  data: GeneratePixProps,
) => {
  try {
    setWebsiteLoading(true)
    const resp = await PIX.generatePix(data)
    
    modalController.openQRCodeModal(resp.pixInfo.copiaecola, true)
  } catch(error: any) {
    modalController.openErrorModal({ title: error?.message })
  } finally {
    setWebsiteLoading(false)
  }
}