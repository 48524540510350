import axios from 'axios'
import Https from 'services/https'
import { type Liveness } from 'store/ducks/liveness/types'

import { 
  LivenessCreateQRCodeResponse, 
  LivenessListFilterProps, 
  LivenessListResponseProps,
  PostLivenessCreateProps, 
} from './liveness/types'

export interface LivenessListData {
  livenessList: Liveness[]
}

const { REACT_APP_API_LIVENESS_ENDPOINT } = process.env

async function newLiveness (gtsBookingId: string): Promise<unknown> {
  const response = await axios.post(`${REACT_APP_API_LIVENESS_ENDPOINT}/create/${gtsBookingId}`, {}, {
    headers: {
      Authorization: localStorage.getItem('@kinto-solutions/gts/gtsAccessToken')
    }
  })

  return response.data
}

export const postGenerateLivessApi = async (data: PostLivenessCreateProps) => 
  await Https.post<LivenessCreateQRCodeResponse>('/gts/create-liveness-unico', data)

export const getLivenessList = async (filters?: LivenessListFilterProps) =>
  await Https.get<LivenessListResponseProps>('/liveness', { params: { ...filters } })

export default {
  newLiveness,
  getLivenessList
}
