import { createBrowserRouter, Navigate } from 'react-router-dom'
import PixScreen from 'views/Pix'

import LogoKinto from './assets/LogoKinto.svg'
import BookingView from './views/Booking'
import NotFound from './views/NotFound'
import SearchBooking from './views/SearchBooking'
import Workspace from './views/Workspace'

function router () {
  return createBrowserRouter([
    {
      path: '/',
      element: <Navigate to={'/auth/booking'} />
    },
    // {
    //   path: "/signin",
    //   loader: () => {
    //     const isAuthed = Boolean(localStorage.getItem("@kinto-solutions/auth/accessToken"))
    //     if (isAuthed) { return redirect("/auth/booking") }

    //     return null
    //   },
    //   element: <SignIn/>
    // },
    {
      path: '/auth',
      element: <Workspace />,
      // loader: () => {
      //   const isAuthed = Boolean(localStorage.getItem("@kinto-solutions/auth/accessToken"))
      //   const expiresAtStr = localStorage.getItem("@kinto-solutions/auth/expiresAt")
      //   const expirasInMinutes = time(expiresAtStr).asDayjs().diff(time(), "minute")

      //   if (expirasInMinutes < 0) {
      //     localStorage.setItem("@kinto-solutions/auth/accessToken", "")
      //     localStorage.setItem("@kinto-solutions/auth/refreshToken", "")
      //     localStorage.setItem("@kinto-solutions/auth/expiresAt", "")
      //     localStorage.setItem("@kinto-solutions/auth/source", "")
      //   }
      //   if (!isAuthed || !expiresAtStr || expirasInMinutes < 0) { return redirect("/signin") }

      //   return null
      // },
      children: [
        {
          path: 'home',
          element: <img src={LogoKinto} alt="Logo Kinto" style={{ width: '50%' }} />
        },
        {
          path: 'admin-pix-generator',
          element: <PixScreen />
        },
        {
          path: 'booking',
          children: [
            {
              path: '',
              element: <SearchBooking />
            },
            {
              path: ':bookingId',
              element: <BookingView />,
              loader: async () => {
                const gtsAccessToken = Boolean(localStorage.getItem('@kinto-solutions/gts/gtsAccessToken'))
                const localId = Boolean(localStorage.getItem('@kinto-solutions/gts/id'))
                const localEmail = Boolean(localStorage.getItem('@kinto-solutions/gts/email'))
                const hasNoAccess = !gtsAccessToken || !localId || !localEmail

                if (hasNoAccess) {
                  window.location.href = '/'
                }

                return null
              }
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <NotFound />
    } // Essa rota sempre deve ser a ultima do array.
  ])
}

export default router
