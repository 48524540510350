import { CheckCircleFilled } from '@ant-design/icons'
import { CopyOutlined } from '@ant-design/icons'
import { ModalFuncProps } from 'antd'
import { HookAPI } from 'antd/es/modal/useModal'
import React from 'react'
import { QRCode } from 'react-qrcode-logo'
import { texts } from 'utils/texts'

class ModalController {
  private _modalController!: HookAPI
  
  public inicialize = (modal: HookAPI) => {
    this._modalController = modal
  }

  public openInfoModal = (options: ModalFuncProps) => 
    this._modalController.info({ ...options })

  public openErrorModal = (options: ModalFuncProps) => 
    this._modalController.error({ ...options })

  public openConfirmModal = (options: ModalFuncProps) => 
    this._modalController.confirm({ ...options })

  public openQRCodeModal = (qrCode: string, copy: boolean = false) => 
    this._modalController.confirm({ 
      title: texts.liveness.newProcess,
      icon: React.createElement(CheckCircleFilled, { style: { color: 'green' } }),
      content: React.createElement('div', {},
        React.createElement('p', {}, texts.liveness.qrCodeTile),
        React.createElement(
          QRCode,
          {
            value: qrCode,
            size: 300,
            logoWidth: 50,
            logoHeight: 35,
            ecLevel: 'M',
            fgColor: '#00708d',
            logoImage: './assets/SimboloKinto.svg',
            removeQrCodeBehindLogo: true
          }
        ),
        React.createElement(
          'a', 
          { 
            href: qrCode,
            target: '_blank'
          }, 
          qrCode
        ),
        copy
          ? React.createElement(
            'span',
            {
              style: { cursor: 'pointer', marginLeft: '8px', color: '#1890ff' }, // Clickable style
              onClick: () => {
                // eslint-disable-next-line no-undef
                navigator.clipboard.writeText(qrCode).then(() => {
                  console.log('QR Code copied to clipboard!')
                }).catch(err => {
                  console.error('Failed to copy QR Code:', err)
                })
              }
            },
            React.createElement(CopyOutlined, {})
          )
          : null
      )
    })
}

const modalController = new ModalController()

export default modalController