
import { getLivenessList } from 'apis/Liveness'
import { LivenessListFilterProps } from 'apis/liveness/types'
import modalController from 'components/Modal'
import { 
  generateLivenessQrCodeService,
  getFaceMatchButtonTextService, 
  getFaceMatchStatusFeedbackService, 
  getLivenessFailedCountService, 
  isFaceMatchButtonAvailableService 
} from 'services/livenessService'

import { useSwrAdapter } from '../swr'

export const useLivenessList = (filters: LivenessListFilterProps, pickUpDatetime?: string) => {
  const hookAdataper = useSwrAdapter({
    filters,
    promiseApi: getLivenessList,
    defaultValue: {
      livenessList: []
    }
  })
  
  const isFaceMatchAvailable = isFaceMatchButtonAvailableService(
    hookAdataper.data, 
    pickUpDatetime
  )

  const faceMatchButtonText = getFaceMatchButtonTextService(hookAdataper.data)
  
  const feedbackLiveness = getFaceMatchStatusFeedbackService(hookAdataper.data, pickUpDatetime)

  const isFaceMatchButtonAvailable = true
  // const isFaceMatchButtonAvailable = isFaceMatchAvailable && (!hookAdataper.isLoading && !hookAdataper.isValidating)

  const livenessReprovedCount = getLivenessFailedCountService(hookAdataper.data)

  const generateLivenessQRCode = () => {
    if(!filters?.bookingId) return modalController.openErrorModal({ title: 'Não é possível iniciar um liveness sem o um Booking Id' })
    if(!filters?.userId) return modalController.openErrorModal({ title:'Não é possível iniciar um liveness sem o um User ID' })

    generateLivenessQrCodeService(filters.bookingId, filters.userId)
  }

  return {
    ...hookAdataper,
    feedbackLiveness,
    isFaceMatchButtonAvailable,
    faceMatchButtonText,
    livenessReprovedCount,
    generateLivenessQRCode
  }
}