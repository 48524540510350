import { useState } from 'react'
import { createPixService, validateUserCredentialsService } from 'services/PixControllerService'
import { PIX_CREATION_STATE_EMPTY } from 'services/PixControllerService/consts'
import { AuthModes } from 'services/PixControllerService/enums'
import { type PixCreationStateProps } from 'services/PixControllerService/types'

const useCreatePix = () => {
  const [state, setState] = useState<PixCreationStateProps>(PIX_CREATION_STATE_EMPTY)

  const onValidateUserSuccess = () => {
    setState(curr => ({
      ...curr,
      mode: AuthModes.GeneratePix
    }))

  }

  const onPressValidateUser = (values: any) => 
    validateUserCredentialsService(values, onValidateUserSuccess)

  const onPressGeneratePix = (values: any) => 
    createPixService({
      bookingId: values.bookingId,
      invoiceId: values.invoiceId,
      value: values.value,
      info: ''
    })

  return {
    ...state,
    onPressGeneratePix,
    onPressValidateUser
  }
}

export default useCreatePix