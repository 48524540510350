import axios from 'axios'

const Https = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
})

Https.interceptors.request.use(config => {
  const authToken = localStorage.getItem('@kinto-solutions/gts/gtsAccessToken')

  config.headers.Authorization = authToken
  return config
})


export default Https