import { postGenerateLivessApi } from 'apis/Liveness'
import { LivenessListObjProps, LivenessListResponseProps, PostLivenessCreateProps } from 'apis/liveness/types'
import { StatusCardType } from 'components/atomic/atoms/StatusCard/enum'
import { StatusCardProps } from 'components/atomic/atoms/StatusCard/types'
import modalController from 'components/Modal'
import { LivenessErrorCounts, LivenessStatus } from 'services/livenessService/enums'
import { setWebsiteLoading } from 'services/LoadingService'
import { executePromiseApi } from 'utils/api'
import { getStaticPropsById } from 'utils/common'
import { DateFormatTypes } from 'utils/time/enums'
import DateHelper from 'utils/time/time'

import { 
  LIVENESS_ERROR_LIMIT, 
  LIVENESS_FACEMATCH_FEEDBACK_LIST, 
  TWO_HOURS_AFTER_PICK_UP, 
  TWO_HOURS_BEFORE_PICK_UP 
} from './consts'
import { 
  LIVENESS_UNAVAILABLE_BY_TIME, 
  LIVENESS_SUCCESS_MESSAGE, 
  LIVENESS_NO_GTS_REGISTER, 
  LIVENESS_NO_GTS_REGISTER_FOR_CURRENT_DATE, 
  LIVENESS_WAINTING
} from './messages'

export const isFaceMatchButtonAvailableService = (livenessListData: LivenessListResponseProps, pickUpDatetime?: string) => {
  if(!livenessListData.livenessList) return false
  
  const isTimeAvialable = validateTimeToEnableFaceMatchService(pickUpDatetime)

  const isStatusAvailabel = validateStatusToEnableStartFaceMatchService(livenessListData)

  const errorCount = livenessListData.livenessList
    .filter(item => new DateHelper(item.createdAt).formatDate(DateFormatTypes.ISO) === new DateHelper(pickUpDatetime).formatDate(DateFormatTypes.ISO))
    .filter(item => item.status === LivenessStatus.Failed || LivenessStatus.Expired)
    .length
  
  const isErrorLimit = errorCount < LIVENESS_ERROR_LIMIT
  
  const isFaceMatchAvailabel = isTimeAvialable && isStatusAvailabel && isErrorLimit
  
  return isFaceMatchAvailabel
}

export const validateTimeToEnableFaceMatchService = (pickUpDatetime?: string): boolean => {
  if(!pickUpDatetime) return false

  const pickUpDatetimeFormatted = new DateHelper(pickUpDatetime).formatDate(DateFormatTypes.ISOTime)

  const hourDiff = new DateHelper().diffDate(pickUpDatetimeFormatted)
  const isAvailable = hourDiff <= TWO_HOURS_AFTER_PICK_UP && hourDiff >= TWO_HOURS_BEFORE_PICK_UP

  return isAvailable
}

export const getRecenetlyLivenessCreatedStatusService = (livenessListData: LivenessListResponseProps) => {
  const recentlyLiveness = livenessListData.livenessList.reduce((latest, current) => {
    return current.createdAt > latest.createdAt ? current : latest
  })

  return recentlyLiveness.status
}

export const validateStatusToEnableStartFaceMatchService = (livenessListData: LivenessListResponseProps) => {
  if(!livenessListData.livenessList.length) return true

  const recentlyLivenessStatus = getRecenetlyLivenessCreatedStatusService(livenessListData)

  const isAvailable = recentlyLivenessStatus === LivenessStatus.Expired 
  || recentlyLivenessStatus === LivenessStatus.Failed
  || !recentlyLivenessStatus

  return isAvailable
}

export const getFaceMatchButtonTextService = (livenessListData: LivenessListResponseProps) => {
  if(!livenessListData.livenessList.length) return 'Iniciar Reconhecimento'
    
  const recentlyLivenessStatus = getRecenetlyLivenessCreatedStatusService(livenessListData)
    
  const statusShouldChangeButtonText = recentlyLivenessStatus === LivenessStatus.Wainting 
    || recentlyLivenessStatus === LivenessStatus.InProgress
    || recentlyLivenessStatus === LivenessStatus.Analysis
    || recentlyLivenessStatus === LivenessStatus.OCR
    
  const message = statusShouldChangeButtonText 
    ? 'Reconhecimento facial em andamento'
    : 'Iniciar Reconhecimento'
    
  return message
}

export const getLivenessListFilteredByCurrentDate = (livenessListData: LivenessListResponseProps): LivenessListObjProps[] => {
  if(!livenessListData.livenessList.length) return []
    
  const currentDate = new DateHelper().formatDate(DateFormatTypes.ISO)
  const dateHelper = new DateHelper()
    
  const livenessListFiltered = livenessListData
    .livenessList
    .filter(item =>
      dateHelper
        .setDate(item.createdAt)
        .formatDate(DateFormatTypes.ISO) === currentDate
    )
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() )
    
  return livenessListFiltered
}

export const getFaceMatchStatusFeedbackService = (livenessListData: LivenessListResponseProps, pickUpDatetime?: string): StatusCardProps => {
  if(!livenessListData.livenessList.length) return { message: '' }
  if(!pickUpDatetime) return { message: LIVENESS_NO_GTS_REGISTER }

  const isTimeAvialable = validateTimeToEnableFaceMatchService(pickUpDatetime)

  if(!isTimeAvialable) return { message: LIVENESS_UNAVAILABLE_BY_TIME }

  const livenessList = getLivenessListFilteredByCurrentDate(livenessListData)
    
  if (!livenessList.length) return { message: LIVENESS_NO_GTS_REGISTER_FOR_CURRENT_DATE }
    
  const latLivessStatus = livenessList[livenessList.length - 1].status

  if(latLivessStatus === LivenessStatus.Sucess) {
    return { 
      message: LIVENESS_SUCCESS_MESSAGE, 
      statusType: StatusCardType.Success 
    } 
  }

  const errorsLenght = livenessList
    .filter(item => item.status === LivenessStatus.Expired || item.status === LivenessStatus.Failed)
    .length

  const errorCount: LivenessErrorCounts = errorsLenght >= 3 
    ? LivenessErrorCounts.ThreeErros
    : errorsLenght as LivenessErrorCounts
  
  if (errorCount <= 0) return { message: LIVENESS_WAINTING }
  
  const feedbak = getStaticPropsById(LIVENESS_FACEMATCH_FEEDBACK_LIST, errorCount)

  return {
    message: feedbak.label,
    statusType: feedbak.statusCardType
  }
}

export const getLivenessFailedCountService = (livenessListData: LivenessListResponseProps): number => {
  if(!livenessListData.livenessList.length) return 0
    
  const livenessReprovedCount = livenessListData
    .livenessList
    .filter(item => item.status === LivenessStatus.Failed)
    .length

  return livenessReprovedCount
}

export const generateLivenessQrCodeService = async (gtsBookingId: string, gtsUserId: string) => {
  try {
    setWebsiteLoading(true)

    const data: PostLivenessCreateProps = {
      gtsBookingId: gtsBookingId,
      gtsUserId: gtsUserId
    }

    const resp = await executePromiseApi(postGenerateLivessApi(data))

    modalController.openQRCodeModal(resp.unicoUrl)
  } catch(error: any) {
    modalController.openErrorModal({ title: error.message })
  } finally {
    setWebsiteLoading(false)
  }
}